import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import OEAutoComplete from '../../../core/components/form/OEAutoComplete';
import { Icon } from '../../../core/entities/Icon';
import { navigateToPageReactRouter } from '../../../core/utilities/URL';
import { useGetSchoolDistricts } from '../../../reporting/services/ReportFilterService';
import { ICoreComponentInfo } from '../../components/OECoreComponent';
import { getSiteSetting, SiteSetting } from '../../entities/SiteSettings';
import '../../styles/homepage.css';
import HomeCard from '../components/HomeCard';
import { useResponsivenessContext } from '../../../core/contexts/Resposiveness';
import { useContainerDimensions } from '../../../core/hooks/useContainerDimensions';

export interface ISchoolOptionsSelection {
    value: string;
    label: string;
}

const Dashboard: React.FunctionComponent<ICoreComponentInfo> = ({ routeProperties }) => {

    const navigate = useNavigate()

    const { service: orgService, setSearch } = useGetSchoolDistricts();
    const [inputValue, setInputValue] = useState<string>('')
    const [schoolDisctricts, setSchoolDistricts] = useState<ISchoolOptionsSelection[]>([])
    const typeScreen = useResponsivenessContext();
    const refBrowse = useRef(null);
    const { width: widthContainer } = useContainerDimensions(refBrowse);

    useEffect(() => {
        if (orgService.result) {
            const d = [];
            for (const v of orgService.result) {
                d.push({ value: v.id, label: v.name });
            }

            setSchoolDistricts(d)
        }
        // eslint-disable-next-line
    }, [orgService]);

    const onNavigate = (value: string) => {
        localStorage.setItem('activeLabels', JSON.stringify(["Home"]));
        navigateToPageReactRouter(navigate, `/kysrc?organization=${value}`)
    }

    const onSearch = (val: string) => {
        setInputValue(val)
        if (val.length > 2) {
            setSearch({ keyword: val, academicYear: getSiteSetting(SiteSetting.AcademicYear), isSchool: true });
        }
    }

    const onStateClick = () => {
        localStorage.setItem('activeLabels', JSON.stringify(["Home"]));
        navigateToPageReactRouter(navigate, `/kysrc?organization=20232024:999:999000`);
    }

    const onSchoolsClick = () => {
        localStorage.setItem('activeLabels', JSON.stringify(["Schools"]));
        navigateToPageReactRouter(navigate, `/schools`);
    }

    const onDistrictsClick = () => {
        localStorage.setItem('activeLabels', JSON.stringify(["Districts"]));
        navigateToPageReactRouter(navigate, `/districts`);
    }

    // const onDataSetsClick = () => {
    //    localStorage.setItem('activeLabels', JSON.stringify(["Resources", "Datasets"]));
    //    navigateToPageReactRouter(navigate, '/data-download?pid=c340f7d5-efbd-5fb8-cab8-3a128835f84c')
    // }

    const onGlossaryClick = () => {
        localStorage.setItem('activeLabels', JSON.stringify(["Resources", "Glossary"]));
        navigateToPageReactRouter(navigate, '/glossary');
    }

    const onReferenceClick = () => {
        localStorage.setItem('activeLabels', JSON.stringify(["Other Education Data"]));
        navigateToPageReactRouter(navigate, '/reference');
    }

    return (

        <>
            <div className='home-container' style={{ flexDirection: typeScreen === "web" ? 'row' : 'column' }}>
                {typeScreen === "web" ? (
                    <div className="left-space">
                        <div className="title-container">
                            <h1>Welcome to the
                                <br></br> <b> Kentucky School
                                    <br></br> Report Card Suite
                                    <br></br> Dashboards</b> 2023-2024
                            </h1>
                        </div>
                    </div>
                ) : (
                    <div className='description-container-responsive'>
                        <h1>Welcome to the Kentucky
                            <br></br> <b>  School Report Card Suite
                                <br></br> Dashboards</b> 2023-2024
                        </h1>
                    </div>
                )
                }
                <div className="right-space">
                    <div className='top'>
                        <div className='card-container flex-column'>
                            <h2 className='find-text'>FIND YOUR SCHOOL OR DISTRICT</h2>
                            <div className='box-container' style={{ flexDirection: 'column' }}>
                                <OEAutoComplete
                                    title="Search below to see detailed information about Kentucky districts and schools"
                                    options={schoolDisctricts}
                                    style={{ width: '100%', borderRadius: 50, height: 40 }}
                                    onNavigate={onNavigate}
                                    onSearch={onSearch}
                                    placeholder="Search by school or district name"
                                    value={inputValue}
                                    titleStyle='title-search-layout'
                                    ariaLabel="Search field for School and/or District"
                                />
                            </div>
                        </div>

                        <div className='card-container flex-column'>
                            <h2 className='find-text'>BROWSE</h2>
                            <div
                                className='find-content'
                                ref={refBrowse}
                                style={{ flexDirection: widthContainer > 520 ? 'row' : 'column' }}
                            >
                                <HomeCard
                                    isBiggerVersion
                                    description='Schools'
                                    urlImage='/images/home/School.svg'
                                    isFirst={true}
                                    onClick={onSchoolsClick}
                                />
                                <HomeCard
                                    isBiggerVersion
                                    description='Districts'
                                    urlImage='/images/home/District.svg'
                                    isFirst={false}
                                    onClick={onDistrictsClick}
                                />
                                <HomeCard
                                    isBiggerVersion
                                    description='Kentucky'
                                    urlImage='/images/home/State_KYSR.svg'
                                    isFirst={false} onClick={onStateClick}
                                />
                            </div>
                        </div>
                        <div className='card-container flex-column'>
                            <h2 className='find-text'>RESOURCES</h2>
                            <div className='flex-row find-content'>
                                {/*<HomeCard*/}
                                {/*    isBiggerVersion={false}*/}
                                {/*    description='Datasets'*/}
                                {/*    urlImage={Icon.Download}*/}
                                {/*    isFirst={true}*/}
                                {/*    onClick={onDataSetsClick}*/}
                                {/*/>*/}

                                <HomeCard
                                    isBiggerVersion={false}
                                    description='Other Education Data'
                                    urlImage={Icon.List}
                                    isFirst={false}
                                    onClick={onReferenceClick}
                                />
                                <HomeCard
                                    isBiggerVersion={false}
                                    description='Glossary'
                                    urlImage={Icon.Dictionary}
                                    isFirst={false}
                                    onClick={onGlossaryClick}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Dashboard;