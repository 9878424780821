import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import OEBreadcrumb from '../../../../core/components/breadcrumbs/OEBreadcrumb';
import { IBreadcrumb } from '../../../../core/components/breadcrumbs/entities/Breadcrumb';
import OEButton from '../../../../core/components/form/OEButton';
import OEDropdown, { IDropdownValues } from '../../../../core/components/form/OEDropdown';
import Menu from '../../../../core/components/menu/Menu';
import { MenuEntity } from '../../../../core/components/menu/entities/MenuEntity';
import OEMessage from '../../../../core/components/messaging/OEMessage';
import OENotification from '../../../../core/components/messaging/OENotification';
import OESpinner, { SpinnerStyle } from '../../../../core/components/messaging/OESpinner';
import { INotification, defaultNotification } from '../../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../../core/components/messaging/enums/InformationMessages';
import { useResponsivenessContext } from '../../../../core/contexts/Resposiveness';
import useWindowDimensions from '../../../../core/hooks/useWindowDimensions';
import { displayHTMLContent } from '../../../../core/utilities/Miscellaneous';
import { emptyGUID } from '../../../../core/utilities/String';
import { navigateToPage, navigateToPageReactRouter, parseQueryString, refreshPage, updateURLParameter } from '../../../../core/utilities/URL';
import AboutSection from '../../../../report-microstrategy/components/common/AboutSection';
import Cube from '../../../../report-microstrategy/components/common/Cube';
import DebugMessage from '../../../../report-microstrategy/components/common/DebugMessage';
import PageFilters from '../../../../report-microstrategy/components/common/PageFilters';
import Report from '../../../../report-microstrategy/components/common/Report';
import TokenValidation from '../../../../report-microstrategy/components/common/TokenValidation';
import SectionGroups from '../../../../report-microstrategy/components/templates/three-tier/SectionGroups';
import SectionTitle from '../../../../report-microstrategy/components/templates/three-tier/SectionTitle';
import { IDossierSettings, checkMSUserSessionExpired, defaultDossierSettings } from '../../../../report-microstrategy/entities/MicroStrategyDossier';
import { IMicroStrategyFilter } from '../../../../report-microstrategy/entities/MicroStrategyFilters';
import { ICubeAttribute } from '../../../../report-microstrategy/entities/api/CubeResponse';
import { IReport, canDebugReport, defaultReport } from '../../../../reporting/entities/Report';
import { IReportConfigurations, ReportConfigurationGroupStyles, ReportConfigurationType, defaultReportConfigurations, getReportConfigurationValue } from '../../../../reporting/entities/ReportConfiguration';
import { IReportGroup, ReportGroupType, defaultReportGroup } from '../../../../reporting/entities/ReportGroup';
import { IReportPage, defaultReportPage } from '../../../../reporting/entities/ReportPage';
import { ProcessingPage, logProcessingPage } from '../../../../reporting/entities/ReportProcessing';
import { useGetReportPublicPagePageByOrganization } from '../../../../reporting/services/ReportPageService';
import { useGetReport, useGetSchoolsInDistrict } from '../../../../reporting/services/ReportService';
import { ICoreComponentInfo } from '../../../../site/components/OECoreComponent';
import SiteFooter from '../../../../site/branding/components/SiteFooter';
import { appRootBreadcrumbs } from '../../../../site/branding/entities/CustomRouteProperties';
import { IHeaderText } from '../../../../site/entities/HeaderText';
import { SiteSetting, getSiteSetting, saveSiteSetting } from '../../../../site/entities/SiteSettings';
import { ISchoolOptionsSelection } from '../../entities/SchoolOptionsSelection';
import { Subheader } from '../Subheader';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

interface IComponentInfo {
    pageId: string;
    organization: string;
    setHeaderWidth: Dispatch<SetStateAction<number>>;
    headerText: IHeaderText;
    menuCollapsed: boolean;
    siteMenu: MenuEntity[];
    isLoadingMenu: boolean;
    baseUrl: string;
    schoolDistricts: ISchoolOptionsSelection[];
    onSearchSchoolDistricts: (val: string) => void;
    academicYears: IDropdownValues[];
    closeMenu: () => void;

}

const MSEmbeddedPublicReport: React.FunctionComponent<IComponentInfo & ICoreComponentInfo> = ({
    pageId, organization, setHeaderWidth, siteMenu, menuCollapsed,
    isLoadingMenu, headerText, routeProperties, baseUrl, schoolDistricts,
    onSearchSchoolDistricts, academicYears, closeMenu
}) => {
    const params: any = parseQueryString();
    const contentRef = useRef<any>();
    const headerRef = useRef<any>();
    const footerRef = useRef<any>();
    const reportRef = useRef<any>();
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(appRootBreadcrumbs);

    const { service: pageService, setItem } = useGetReportPublicPagePageByOrganization();
    const { service: reportService, setItemId: setGetReportId } = useGetReport();
    const { service: schoolService, setOrgCode: setSchoolOrgCode } = useGetSchoolsInDistrict();

    const [debug] = useState<boolean>(canDebugReport);
    const [processingStep, setProcessingStep] = useState<ProcessingPage>(ProcessingPage.Idle);
    const [pageFilters, setPageFilters] = useState<IMicroStrategyFilter[]>([]);
    const [notiication, setNotification] = useState<INotification>(defaultNotification);

    const [validToken, setValidToken] = useState<boolean>(false);
    const [compareReports, setCompareReports] = useState<boolean>(false);
    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageSettings, setPageSettings] = useState<IDossierSettings>(defaultDossierSettings);
    const [section, setSection] = useState<IReportGroup>(defaultReportGroup);
    const [defaultSection, setDefaultSection] = useState<IReportGroup>(defaultReportGroup);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const [selectedGroup, setSelectedGroup] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [cubeId, setCubeId] = useState<string>('');
    const [cubeAttributes, setCubeAttributes] = useState<ICubeAttribute[]>([]);

    const [footer, setFooter] = useState<string>('');
    const [reportHeight, setReportHeight] = useState<number>(0);
    const [configurations, setConfigurations] = useState<IReportConfigurations>(defaultReportConfigurations);
    const [debugMessage, setDebugMessage] = useState<string>('');
    const [report, setReport] = useState<IReport>(defaultReport);

    const [widthContent, setWidthContent] = useState<number>(2048);
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const [activeLabels, setActiveLabels] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [year, setYear] = useState<string>(getSiteSetting(SiteSetting.AcademicYear));
    const typeScreen = useResponsivenessContext();
    const [schools, setSchools] = useState<IDropdownValues[]>([]);
    const [school, setSchool] = useState<string>('');

    useEffect(() => {
        if (schoolService.result) {
            setSchools(schoolService.result);
        }
        // eslint-disable-next-line
    }, [schoolService]);

    const onSelectSchool = (i: string) => {
        updateURLParameter('organization', i);
        setSchool(i);
        refreshPage();
    }

    useEffect(() => {
        if (activeLabels) {
            localStorage.setItem('activeLabels', JSON.stringify(activeLabels));
        }
    }, [activeLabels])

    useEffect(() => {
        const active = localStorage.getItem('activeLabels');
        setSchoolOrgCode(params['organization']);
        setSchool(params['organization']);
        if (active) {
            setActiveLabels(JSON.parse(active));
        }
        try {
            window.onresize = onWindowResized;
            const storeSize = getSiteSetting(SiteSetting.ReportContentWidth);

            if (storeSize) {
                setWidthContent(typeScreen === "mobile" ? width : parseInt(storeSize))
                setHeaderWidth(typeScreen === "mobile" ? width : parseInt(storeSize))
            } else {
                // Default value 1280
                const currentWidth = typeScreen === "mobile"
                    ? width : width - 290 > 1280
                        ? 1280 : width - 290;
                setWidthContent(currentWidth)
                setHeaderWidth(currentWidth)
            }

            if (!contentRef.current) return;
            const resizeObserver = new ResizeObserver(() => {
                if (contentRef && contentRef.current) {
                    console.log(contentRef.current.clientWidth)
                    setHeaderWidth(contentRef.current.clientWidth)
                    saveSiteSetting(SiteSetting.ReportContentWidth, contentRef.current.clientWidth);
                }
            });
            resizeObserver.observe(contentRef.current);
            return () => resizeObserver.disconnect();
        }
        catch {
            console.log('resizing failed');
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        logProcessingPage(processingStep, debug);
        if (processingStep !== ProcessingPage.FatalError) {
            setError('');
        }
        switch (processingStep) {
            case ProcessingPage.PageStart:
                setProcessingStep(ProcessingPage.PageLoad);
                break;

            case ProcessingPage.PageLoad:
                setItem({ organization, id: pageId });
                break;

            case ProcessingPage.PageLoaded:
                try {
                    setProcessingStep(ProcessingPage.ReportLoad);
                    setPageSettings({
                        ...defaultDossierSettings,
                        collapsibleSections: getReportConfigurationValue(ReportConfigurationType.CollapsibleSections, configurations.pageConfiguration)
                    });
                }
                catch {
                    setProcessingStep(ProcessingPage.FatalError);
                    setError(`Cube could not be setup based on the first report for this page.`);
                }
                break;

            case ProcessingPage.ReportLoad:
                let reportId: string = '';

                for (const c of page.groups[0].groups[0].groups.filter(q=>q.reportCount > 0)) {
                    if (c.reports.length > 0) {
                        reportId = c.reports[0].reportId;
                        break;
                    }
                }

                if (reportId === '') {
                    setProcessingStep(ProcessingPage.FatalError);
                    const sectionTitle: string = page.groups[0].groups[0].title;
                    setError(`There are no reports setup for the section <b><i>${sectionTitle}</b></i>`);
                } else {
                    if (cubeAttributes.length === 0) {
                        setGetReportId(reportId);
                        setProcessingStep(ProcessingPage.PageFiltersLoad);
                    }
                    else {
                        setProcessingStep(ProcessingPage.SectionGroupsInitialize);
                    }
                }
                break;

            case ProcessingPage.PageFiltersLoaded:
                onUpdateBreadcrumbs();
                setProcessingStep(ProcessingPage.SectionLoad);
                break;

            case ProcessingPage.SectionLoad:
                if (!setSectionByID(getSiteSetting(SiteSetting.MSSectionId))) {
                    onChangeSectionTitle(page.groups.filter(q => q.isActive)[0]);
                }
                break;

            case ProcessingPage.SectionLoaded:
                saveSiteSetting(SiteSetting.MSSectionId, section.id);
                break;

            case ProcessingPage.GroupLoad:
                loadGroup();
                break;

            case ProcessingPage.GroupLoaded:
                setProcessingStep(ProcessingPage.Complete);
                break;


            case ProcessingPage.SectionGroupsInitialized:
            case ProcessingPage.PageFiltersUpdated:
                setProcessingStep(ProcessingPage.Complete);
                break;

            case ProcessingPage.Complete:
                setCompareReports(getReportConfigurationValue(ReportConfigurationType.CompareReports, configurations.pageConfiguration));
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (validToken) {
            setProcessingStep(ProcessingPage.PageStart);
        }
        // eslint-disable-next-line
    }, [validToken]);

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        if (page.id !== '') {
            setProcessingStep(ProcessingPage.PageLoaded);
            setConfigurations({ ...configurations, pageConfiguration: page.configuration });
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (section.id !== '') {
            setProcessingStep(ProcessingPage.SectionLoaded);
            setConfigurations({ ...configurations, sectionConfiguration: section.configuration });
        }
        // eslint-disable-next-line
    }, [section]);

    useEffect(() => {
        if (group.id !== '') {
            if (!getReportConfigurationValue(ReportConfigurationType.MultipleReports, group.configuration)) {
                if (group.reports.length > 1) {
                    group.reports = [{ ...group.reports[0] }];
                }
            }
            setProcessingStep(ProcessingPage.GroupLoad);
            setConfigurations({ ...configurations, groupConfiguration: group.configuration });
        }
        // eslint-disable-next-line
    }, [group]);

    useEffect(() => {
        if (pageFilters.length > 1) {
            onUpdateBreadcrumbs();
        }
        // eslint-disable-next-line
    }, [pageFilters]);


    useEffect(() => {
        const orgcode: string = params['organization'];
        const codes: string[] = orgcode.split(':');
        const s: string = routeProperties.academicYear.replace('-', '');
        if (codes.length > 2) {
            if (codes[0] !== s) {
                codes[0] = s;
                updateURLParameter('organization', `${codes[0]}:${codes[1]}:${codes[2]}`);
                refreshPage();
                // setNotification({ type: 'error', duration: 10000, message: `${params['organization']} : ${routeProperties.academicYear}` });
            }
        }
        // eslint-disable-next-line
    }, [routeProperties.academicYear]);

    const loadGroup = () => {
        saveSiteSetting(SiteSetting.MSGroupId, group.id);
        if (group.reports.length > 0) {
            setFooter(getReportConfigurationValue(ReportConfigurationType.ReportFoooter, configurations.groupConfiguration));
        }
        setPageSettings({
            ...pageSettings,
            hideFilterSummary:
                getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.pageConfiguration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.sectionConfiguration)
                || getReportConfigurationValue(ReportConfigurationType.HideFilterSummary, configurations.groupConfiguration),
        });
        setProcessingStep(processingStep === ProcessingPage.SectionGroupsInitialize ? ProcessingPage.SectionGroupsInitialized : ProcessingPage.GroupLoaded);
    }

    const onWindowResized = () => {
        onUpdateHeight();
    }

    const clear = () => {
        setSelectedGroup('')
    }

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        navigateToPageReactRouter(navigate, '/home')
    };

    const onUpdateBreadcrumbs = () => {
        const b: IBreadcrumb[] = [...appRootBreadcrumbs];
        // need to eventually code this to be generic
        const bUrl = `${baseUrl ? `${baseUrl}?` : `embedded-public?pid=${pageId}&`}organization=${pageFilters[0].values.filter(q => q.parentId === pageFilters[0].selection)[0].id.replace('h', '')}`;
        b.push({ id: 1, name: 'Kentucky', url: `${bUrl}:999:999000` });
        const districtList = pageFilters[1];
        const schoolList = pageFilters[2];
        const d: string = districtList.selection;
        const s: string = schoolList.selection;
        if (d !== districtList.values[0].parentId) {
            const did = districtList.values.find(q => q.parentId === d);
            const sid = schoolList.values.find(q => q.parentId === s);

            if (did && sid) {
                b.push({ id: 1, name: `${did.name}`, url: `${bUrl}:${did.id.replace('h', '')}:${schoolList.values[0].id.replace('h', '')}` });
                if (s !== schoolList.values[0].parentId) {
                    b.push({ hidden: typeScreen === "web" || schools.length > 1, id: 1, name: `${sid.name}`, url: `${bUrl}:${did.id.replace('h', '')}:${sid.id.replace('h', '')}` });
                }
            }
        }
        setBreadcrumbs(b);

    }
    const onUpdateHeight = () => {
        if (headerRef.current) {
            let h: number =
                headerRef.current.getBoundingClientRect().height
                + (footerRef.current ? footerRef.current.getBoundingClientRect().height : 16)
                + headerRef.current.offsetTop + 55
                ;

            // subrtract height of tabs
            if (getReportConfigurationValue(ReportConfigurationType.GroupStyle, configurations.sectionConfiguration) === ReportConfigurationGroupStyles.Tabs) {
                h = h - 40;
            }

            // subtract height of report title
            if (group.reports.length > 1) {
                h = h - 20;
            }
            setReportHeight(h);
            //    setNotification({ duration: 10000, message: `report top ${headerRef.current.getBoundingClientRect().height}, 
            //    footer ${footerRef.current ? footerRef.current.getBoundingClientRect().height : 0}, header ${headerRef.current.offsetTop}, totalHeight ${h}`, type: 'info' });
        }
    }

    const setSectionByID = (id: string): boolean => {
        for (const sh of page.groups.filter(q => q.isActive)) {
            for (const sl of sh.groups.filter(q => q.isActive)) {
                if (getReportConfigurationValue(ReportConfigurationType.DefaultSection, sl.configuration)) {
                    setConfigurations({ ...configurations, sectionTitleConfiguration: sh.configuration });
                    setDefaultSection(sl);
                }
                if (sl.id === id) {
                    setSection(sl);
                    setConfigurations({ ...configurations, sectionTitleConfiguration: sh.configuration });
                    setSelectedGroup(sh.id);
                    return true;
                }
            }
        }
        return false;
    }

    const onChangeSection = (i: IReportGroup) => {
        if (i) {
            saveSiteSetting(SiteSetting.MSGroupId, '');
            setGroup(defaultReportGroup);
            setSection(i);
        }
    }

    const onChangeSectionTitle = (i: IReportGroup) => {

        setActiveLabels([]);
        localStorage.setItem('activeLabels', JSON.stringify([]));
        if (i.title === 'Home') {
            navigateToPageReactRouter(navigate, '/home')
        }

        setConfigurations({ ...configurations, sectionTitleConfiguration: i.configuration });
        if (selectedGroup !== i.id) {
            for (const c of i.groups.filter(q => q.isActive)) {
                if (getReportConfigurationValue(ReportConfigurationType.DefaultSection, c.configuration)) {
                    setDefaultSection(c);
                    break;
                }
            }
            setSelectedGroup(i.id);
            onChangeSection(i.groups.filter(q => q.isActive)[0]);
        }
        else {
            for (const c of i.groups.filter(q => q.isActive)) {
                if (getReportConfigurationValue(ReportConfigurationType.DefaultSection, c.configuration)) {
                    onChangeSection(c);
                    break;
                }
            }
        }
    }

    const onSearch = (val: string) => {
        setInputValue(val)
        onSearchSchoolDistricts(val);
    }

    const onNavigate = (value: string) => {
        navigateToPage(`/kysrc?organization=${value}`)
    }

    useEffect(() => {
        if (reportService.result) {
            setReport(reportService.result.report);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (!emptyGUID(report.id)) {
            setCubeId(getReportConfigurationValue(ReportConfigurationType.CubeID, report.configuration));
        }
        // eslint-disable-next-line
    }, [report]);

    const setActiveGroup = (i: IReportGroup) => {
        setGroup(i);
    }

    const setCube = (c: ICubeAttribute[]) => {
        setCubeAttributes(c);
        console.log(debugMessage);
    }

    const setCubeError = (e: string) => {
        if (!checkMSUserSessionExpired(e)) {
            setError(`Cube Load Error: ${e}`);
        }
        setProcessingStep(ProcessingPage.FatalError);
    }

    const order = typeScreen === "web" ? true : menuCollapsed
    return (
        <div className="report-embedded m-b-0">
            <OENotification setNotification={setNotification} notification={notiication} />
            <TokenValidation isPublic={emptyGUID(routeProperties.user.id)} validToken={validToken} setValidToken={setValidToken} />
            <DebugMessage debug={debug}>Page: {processingStep}</DebugMessage>
            <Cube
                validToken={validToken}
                setCube={setCube}
                report={report}
                setNotification={setNotification}
                cubeId={cubeId}
                setError={setCubeError}
                setDebugMessage={setDebugMessage}
            />

            {pageId !== '' && (
                <>
                    <div className="three-tier">
                        <div className="left-nav transiction"
                            style={{
                                width: order ? 290 : 0,
                                display: order ? 'block' : 'none',
                                position: typeScreen !== "web" ? 'absolute' : 'relative',
                                backgroundColor: typeScreen !== "web" ? '#ffffff' : '#f7f7f7',
                                borderRight: typeScreen !== "web" ? '1px solid #BEBEBE' : '',
                                overflowY: typeScreen !== "web" ? 'hidden' : 'scroll',
                                zIndex: 1000
                            }}>
                            <div style={{ padding: '10px 20px 10px 20px' }}>
                                <div className='separator' />
                            </div>
                            {page.groups.filter(q => q.isActive).map((item, index) =>
                                <SectionTitle
                                    closeMenu={closeMenu}
                                    key={item.id}
                                    onClick={onChangeSectionTitle}
                                    onChangeSection={onChangeSection}
                                    selected={item.id === selectedGroup}
                                    defaultSection={defaultSection}
                                    selectedSection={section.id}
                                    item={item}
                                    collapsible={pageSettings.collapsibleSections}
                                    clear={clear}
                                />
                            )}
                            <div style={{ padding: '10px 20px 10px 20px' }}>
                                <div className='separator' />
                            </div>
                            <Menu
                                items={siteMenu.map((st) => {
                                    return {
                                        ...st,
                                        onClick: (e) => {
                                            st.onClick(e);
                                            clear();
                                        }
                                    }
                                })}
                                collapsed={menuCollapsed}
                                isLoading={isLoadingMenu}
                                activeLabels={activeLabels}
                                setActiveLabels={setActiveLabels}
                            />

                        </div>
                        <div className='right-space' onClick={closeMenu}>
                            {typeScreen !== "web" ?
                                (<>
                                    <div className="breadcumb-container">
                                        <OEBreadcrumb
                                            isBlack={false}
                                            breadcrumbs={breadcrumbs}
                                            setBreadcrumbs={setBreadcrumbs}
                                            navigateBreadcrumb={onNavigateBreadcrumb}
                                            className='breadcrumb-reporting-page'
                                        />
                                        <OEDropdown
                                            values={academicYears}
                                            value={year}
                                            onChange={setYear}
                                            className='dropdown-header-year'
                                        />
                                    </div>
                                    <Subheader
                                        year={year}
                                        setYear={setYear}
                                        onSearch={onSearch}
                                        onNavigate={onNavigate}
                                        academicYears={academicYears}
                                        schoolDistricts={schoolDistricts}
                                        inputValue={inputValue}
                                    />
                                </>
                                )
                                :
                                (
                                    <div className="breadcumb-container">
                                        <OEBreadcrumb
                                            isBlack={false}
                                            breadcrumbs={breadcrumbs}
                                            setBreadcrumbs={setBreadcrumbs}
                                            navigateBreadcrumb={onNavigateBreadcrumb}
                                            className='breadcrumb-reporting-page'
                                        >
                                            {schools.length > 0 && breadcrumbs.length > 1 && (
                                                <div className="dropdown-breadcrumb-select">
                                                    <OEDropdown
                                                        values={schools}
                                                        value={school}
                                                        onChange={onSelectSchool}
                                                        className='dropdown-breadcrumb'
                                                    />
                                                </div>
                                            )}
                                        </OEBreadcrumb>
                                    </div>
                                )}
                            <div className="subheader">
                                {compareReports && typeScreen === "web" && (
                                    <OEButton className="m-t-10 m-b-10 pull-right btn-sm btn-dark" onClick={() => {
                                        saveSiteSetting(SiteSetting.SchoolCompare, JSON.stringify({ pageId: pageId, organization: organization, schoolName: headerText.middle }));
                                        navigateToPageReactRouter(navigate, `/school-compare?name=${headerText.left}&organization=${organization}`);
                                    }} text="Compare" />
                                )}
                                <p className='top-left-text'>{headerText.left}</p>
                                <div
                                    className='info-container-bottom'
                                    style={{
                                        flexDirection: typeScreen === "mobile" ? "column" : "row"
                                    }}>
                                    <p className='bottom-left-text'>{`${headerText.title}: ${headerText.contact}`}</p>
                                    <div>
                                        <img src={`/images/email.svg`} className="m-l-20" width={14} alt="email-icon" />
                                        <a href={`mailto:${headerText.email}`} className='bottom-left-text m-l-5'>{headerText.email}</a>
                                    </div>
                                </div>
                            </div>
                            <div className='content'
                                style={{
                                    padding: typeScreen !== "mobile" ? '20px 20px 0px 10px' : '',
                                    overflowY: typeScreen !== "web" ? 'unset' : 'scroll'
                                }}>
                                <div className='content-inner'
                                    ref={contentRef}
                                    style={{
                                        width: widthContent,
                                        resize: typeScreen !== "mobile" ? "horizontal" : undefined
                                    }}

                                >
                                    <div ref={headerRef} >
                                        <PageFilters
                                            setNotification={setNotification}
                                            pageProcessingStep={processingStep}
                                            page={page}
                                            report={report}
                                            setPageProcessingStep={setProcessingStep}
                                            setPageFilters={setPageFilters}
                                            cubeAttributes={cubeAttributes}
                                            setDebugMessage={setDebugMessage}
                                            initialValues={organization}
                                            hidden={true}
                                        />
                                        {processingStep !== ProcessingPage.FatalError && section.groups.filter(q => q.isActive).length > 0 && (
                                            <SectionGroups
                                                pageFilters={pageFilters}
                                                section={section}
                                                activeGroup={group}
                                                setActiveGroup={setActiveGroup}
                                            />
                                        )}
                                    </div>
                                    {processingStep === ProcessingPage.FatalError && (
                                        <OEMessage
                                            className="report-errors"
                                            hideDismissable={true}
                                            message={error}
                                            type={MessageType.Error}
                                        />
                                    )}
                                    {processingStep !== ProcessingPage.FatalError && (
                                        <div ref={reportRef} className="report-title">
                                            {group.id !== '' && processingStep === ProcessingPage.Complete && (
                                                <>
                                                    {group.groupType === ReportGroupType.AboutPage && (
                                                        <AboutSection group={group} />
                                                    )}
                                                    {group.reports.filter(q => q.isActive).map((item4, index4) =>
                                                        <Report
                                                            key={index4}
                                                            reportId={item4.reportId}
                                                            setNotification={setNotification}
                                                            pageFilters={pageFilters}
                                                            pageProcessingStep={processingStep}
                                                            divId={`report${item4.reportId}`}
                                                            configurations={{ ...configurations, reportConfiguration: item4.configuration }}
                                                            pageSettings={pageSettings}
                                                            reportHeight={reportHeight}
                                                            groupType={group.groupType}
                                                            isPublic={true}
                                                            multipleReports={getReportConfigurationValue(ReportConfigurationType.MultipleReports, configurations.groupConfiguration)}
                                                        />

                                                    )}

                                                    {footer && (
                                                        <div className="footer" ref={footerRef} dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                                                    )}
                                                </>
                                            )}
                                            {group.id !== '' && processingStep !== ProcessingPage.Complete && (
                                                <OESpinner message="Loading Report" oeStyle={SpinnerStyle.Small} />
                                            )}
                                            {section.groups.length === 0 && section.id !== '' && (
                                                <OEMessage className="h5" type={MessageType.Danger} hideDismissable={true} message={`No groups have been set up for ${section.title} `} />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <SiteFooter />
                        </div>
                    </div>
                </>
            )
            }

        </div >
    );
};

export default MSEmbeddedPublicReport;
