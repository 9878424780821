import React, { Children, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ChatBtn from '../../chat/components/ChatBtn';
import ChatWidget from '../../chat/components/ChatWidget';
import { IDropdownValues } from '../../core/components/form/OEDropdown';
import { MenuEntity } from '../../core/components/menu/entities/MenuEntity';
import { getMenuChildren } from '../../core/components/menu/Menu';
import useWindowDimensions from '../../core/hooks/useWindowDimensions';
import { redirectLogin } from '../../core/services/Authentication';
import { emptyGUID } from '../../core/utilities/String';
import { useGetAcademicYears, useGetSchoolDistricts } from '../../reporting/services/ReportFilterService';
import { SessionController } from '../components/SessionController';
import SkipControl from "../components/SkipControl";
import { IRoute } from '../entities/Route';
import { getSiteSetting, SiteSetting } from '../entities/SiteSettings';
import { useGetUserMenus } from '../services/MenuService';
import { ICustomRouteProperties } from './entities/CustomRouteProperties';
import { ISchoolOptionsSelection } from './entities/SchoolOptionsSelection';
import DefaultLayout from './layouts/DefaultLayout';
import ReportingLayout from './layouts/ReportingLayout';

interface IComponentInfo {
    children?: any;
    routes: IRoute[];
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void
}

const Layout: React.FunctionComponent<IComponentInfo> = ({ children, routes, routeProperties, setRouteProperties }) => {
    const { service: yearService } = useGetAcademicYears(true);
    const { service: menuService } = useGetUserMenus();
    const { service: orgService, setSearch } = useGetSchoolDistricts();

    const [logout] = useState<boolean>(false);
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);
    const { width } = useWindowDimensions();
    const [showChat, setShowChat] = useState(false);
    const [siteMenu, setSiteMenu] = useState<MenuEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { pathname } = useLocation();
    const [schoolDistricts, setSchoolDistricts] = useState<ISchoolOptionsSelection[]>([])
    const [years, setYears] = useState<IDropdownValues[]>([]);


    const navigate = useNavigate();

    useEffect(() => {
        if (!getSiteSetting(SiteSetting.PublicAccessible) && emptyGUID(routeProperties.user.id)) {
            redirectLogin();
        }
        setSchoolDistricts([]);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (orgService.result) {
            const d = [];
            for (const v of orgService.result) {
                d.push({ value: v.id, label: v.name });
            }
            setSchoolDistricts(d)
        }
        // eslint-disable-next-line
    }, [orgService]);

    useEffect(() => {
        if (yearService.result) {
            setYears(yearService.result);
            // saveSiteSetting(SiteSetting.AcademicYear, yearService.result[0].id);
        }
    }, [yearService]);

    useEffect(() => {
        if (menuService.result) {
            const publicMenu: number = getSiteSetting(SiteSetting.MSPublicMenuId);
            const menuOptions = getMenuChildren(menuService.result.items.filter(q => q.isActive), 0, 0, navigate, closeMenu)
            const publicOptions = getMenuChildren(menuService.result.items, publicMenu, publicMenu, navigate, closeMenu)
            setSiteMenu([...menuOptions, ...publicOptions])
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [menuService]);

    useEffect(() => {
        if (years.length) {
            const i = years.find(q => q.id === getSiteSetting(SiteSetting.AcademicYear));
            if (i) {
                setRouteProperties({ ...routeProperties, academicYearID: i.id, academicYear: i.name });
            }
            else {
                setRouteProperties({ ...routeProperties, academicYearID: years[0].id, academicYear: years[0].name });
            }
        }
        // eslint-disable-next-line
    }, [years]);

    const toggleCollapse = () => {
        setMenuCollapsed(!menuCollapsed)
    }

    const closeMenu = () => {
        setMenuCollapsed(false)
    }

    const onSearch = (val: string) => {
        if (val.length > 2) {
            setSearch({
                keyword: val,
                academicYear: getSiteSetting(SiteSetting.AcademicYear), isSchool: true
            });
        }
        else {
            setSchoolDistricts([]);
        }
    }

    const isReportingPage = (): boolean => {
        switch (pathname) {
            case '/embedded-report-public':
            case '/embedded-public':
            case '/kysrc':
                return true;
            default:
                return false;
        }
    }

    return (
        <SessionController settings={routeProperties.settings} logout={logout} >
            <SkipControl />
            {isReportingPage() && (
                <ReportingLayout
                    closeMenu={closeMenu}
                    width={width}
                    toggleCollapse={toggleCollapse}
                    menuCollapsed={menuCollapsed}
                    siteMenu={siteMenu}
                    isLoadingMenu={isLoading}
                    routes={routes}
                    routeProperties={routeProperties}
                    setRouteProperties={setRouteProperties}
                    schoolDistricts={schoolDistricts}
                    onSearchSchoolDistricts={onSearch}
                    academicYears={years}
                />
            )}

            {!isReportingPage() && (
                <DefaultLayout
                    closeMenu={closeMenu}
                    width={width}
                    toggleCollapse={toggleCollapse}
                    menuCollapsed={menuCollapsed}
                    siteMenu={siteMenu}
                    isLoadingMenu={isLoading}
                    routes={routes}
                    routeProperties={routeProperties}
                    setRouteProperties={setRouteProperties}
                    schoolDistricts={schoolDistricts}
                    onSearchSchoolDistricts={onSearch}
                    academicYears={years}
                >
                    {Children.map(children, (i) => i)}
                </DefaultLayout>
            )}
            {routeProperties.settings.chatbotEnabled && (
                <>
                    <ChatBtn showChat={showChat} setShowChat={setShowChat} />
                    <ChatWidget showChat={showChat} setShowChat={setShowChat} />
                </>
            )}
        </SessionController>
    );
};

export default Layout;
