import React, { useEffect, useState } from 'react';
import OEAutoComplete from '../../../../core/components/form/OEAutoComplete';
import OELink from '../../../../core/components/general/OELink';
import { Icon } from '../../../../core/entities/Icon';
import { parseQueryString } from '../../../../core/utilities/URL';
import { useGetSchoolDistricts } from '../../../../reporting/services/ReportFilterService';
import { getSiteSetting, SiteSetting } from '../../../../site/entities/SiteSettings';
import { ICoreComponentInfo } from '../../../components/OECoreComponent';
import { ISchoolOptionsSelection } from '../../entities/SchoolOptionsSelection';
import MSSchoolCompare from './MSSchoolCompare';

const styles = {
    container: {
        overflowY: 'scroll',
        display: 'flex',
        gap: '10px',
    } as React.CSSProperties,
    column: {
        display: 'flex',
        width: '100vw',
    } as React.CSSProperties,
    columnSchool: {
        height: '100vh',
        overflowY: 'auto',
        padding: '15px',
        border: '1px solid #ddd',
        boxSizing: 'border-box',
    } as React.CSSProperties,
    item: {
        margin: '0',
        padding: 10,
        fontSize: '16px',
        cursor: 'pointer',
    } as React.CSSProperties,
};

const SchoolCompare: React.FunctionComponent<ICoreComponentInfo> = ({ routeProperties, setRouteProperties }) => {
    const params: any = parseQueryString();
    const { service: orgService, setSearch } = useGetSchoolDistricts();

    const [schoolDisctricts, setSchoolDistricts] = useState<ISchoolOptionsSelection[]>([]);
    const [inputValue, setInputValue] = useState<string>('')
    const [compareList, setCompareList] = useState<ISchoolOptionsSelection[]>([]);
    const [organization] = useState(params['organization'] || '');
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (orgService.result) {
            const d = [];
            for (const v of orgService.result) {
                d.push({ value: v.id, label: v.name });
            }
            setSchoolDistricts(d)
        }
        // eslint-disable-next-line
    }, [orgService]);

    useEffect(() => {
        !initialized && setSearch({ keyword: params['name'], academicYear: getSiteSetting(SiteSetting.AcademicYear), isSchool: true });
        // eslint-disable-next-line
    }, [organization]);

    useEffect(() => {
        !initialized && onAddSchool(organization);
        // eslint-disable-next-line
    }, [schoolDisctricts]);

    const onAddSchool = (value: string) => {
        if (compareList.length !== 4 && value && compareList.filter(q => q.value === value).length === 0) {
            const filterSchool = schoolDisctricts.find(item => item.value === value);
            if (filterSchool) {
                setInitialized(true);
                setCompareList([...compareList, filterSchool]);
                setInputValue("");
                setSchoolDistricts([]);
            }
        }
    }

    const onSearch = (val: string) => {
        setInputValue(val)
        if (val.length > 2) {
            setSearch({ keyword: val, academicYear: getSiteSetting(SiteSetting.AcademicYear), isSchool: true });
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.column}>
                {compareList.length > 0 ? <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${compareList.length}, 1fr)`,
                        gap: '10px',
                        width: '100%',
                    }}
                >
                    {compareList.map((item, index) => (
                        <div key={index} style={styles.columnSchool}>
                            <OELink icon={Icon.Close} className="pull-left" onClick={() => {
                                const updatedArray = compareList.filter(school => school.value !== item.value);
                                setCompareList(updatedArray);
                            }} />
                            <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.label}</span>
                            <MSSchoolCompare organization={item.value} reportId="533e76e6-ef73-7d82-603b-3a16aa881b9d" routeProperties={routeProperties} setRouteProperties={setRouteProperties} />
                        </div>
                    ))}
                </div> : null}
                {compareList.length < 4 && <div className='box-container p-2'>
                    <OEAutoComplete
                        title="Add Schools or Districts name"
                        options={schoolDisctricts}
                        style={{ width: '100%', borderRadius: 50, height: 40, minWidth: 250 }}
                        onNavigate={onAddSchool}
                        onSearch={onSearch}
                        placeholder="Add School or District name"
                        value={inputValue}
                        titleStyle='title-search-layout'
                        ariaLabel="Search field for School and/or District"
                    />
                </div>}
            </div>
        </div>
    )
}

export default SchoolCompare;

