import { IDropdownValues } from "../../core/components/form/OEDropdown";
import { IBaseAuditedEntity } from "../../core/entities/BaseAuditedEntity";
import { IBaseList } from "../../core/entities/BaseList";
import { getEmptyGUID } from "../../core/utilities/String";
import { IReport, ReportFilterLocation } from "../../reporting/entities/Report";
import { MicroStrategyDossierFilterType } from "./MicrostrategyDossierFilter";

export const ReportNoFilterTextValue = "ZZZ-ZZZ";

export enum ReportFilterType {
    Dropdown = 'DD',
    Date = 'DA',
    Text = 'TE',
    Number = 'NU',
    MultiSelect = 'MS',
}

export interface IReportBaseFilter extends IBaseAuditedEntity {
    id: string;
    label: string;
    name: string;
    externalId: string;
    showSelectAll: boolean;
    selectAllText: string;
    sortOrder: number;
    returnValues: boolean;
    overrideValue: string;
    dossier: boolean;
    rangeIncrements?: string;
    ignoreFilter?: boolean;
    noFilterText?: string;
    defaultSelection?: string;
    cascadePriority: number;
    location?: ReportFilterLocation;
    msId: string;
    msName: string;
    cubeId: string;
    mulitipleSelect: boolean;
    rawValueSortIndex: number;
    filterType: string;
    selectAllLabel: string;
}

export const defaultReportBaseFilter: IReportBaseFilter = {
    id: getEmptyGUID(), label: '', sortOrder: 0, isSet: false, name: '',
    externalId: '', showSelectAll: true, returnValues: true, overrideValue: '',
    dossier: false, msId: '', selectAllText: '', ignoreFilter: false, cascadePriority: 99, msName: '',
    cubeId: '', mulitipleSelect: false, filterLoaded: false, rawValueSortIndex: -1, selectAllLabel: '',
    filterType: MicroStrategyDossierFilterType.SingleSelect
};

export interface IReportFilter extends IReportBaseFilter {
    properties: string;
    reportId: string;
    reportFilterTypeId: string;
    value: string;
    valueName: string;
    defaultValue: string;
    cascading: boolean;
    values: IDropdownValues[];
}

export const defaultReportFilter: IReportFilter = {
    ...defaultReportBaseFilter, properties: '', reportId: '', reportFilterTypeId: '',
    value: '', defaultValue: '', cascading: false, values: [], name: '', valueName: ''
};

export interface IReportFilterSelectionValues {
    name: string;
    values: IDropdownValues[];
}

export const defaultReportFilterValues: IReportFilterSelectionValues = {
    name: '', values: [],
};

export interface IReportFilterListItem extends IBaseAuditedEntity {
    reportFilter: IReportFilter;
    report: IReport;
    reportFilterType: any;
}

export const getReportFilterList = (i: IBaseList<IReportFilterListItem>): IReportFilter[] => {
    const d: IReportFilter[] = [];
    for (const v of i.items) {
        d.push({
            ...v.reportFilter,
        });
    }
    return sortConfigruationArray(d);
};

export const getReportBaseFilterFromJSON = (p: string): IReportBaseFilter[] => {
    if (p === null) {
        return [];
    }
    try {
        const i: IReportBaseFilter[] = JSON.parse(p);
        for (const f of i) {
            f.filterType = f.filterType || MicroStrategyDossierFilterType.SingleSelect;
        }
        return i;
    }
    catch {
        return [];
    }
}

export const getReportFilterFromJSON = (p: string): IReportFilter[] => {
    if (p === null) {
        return [];
    }
    try {
        const i: IReportBaseFilter[] = JSON.parse(p);
        const j: IReportFilter[] = [];
        for (const f of i) {
            j.push({ ...defaultReportFilter, ...f, filterType: f.filterType || MicroStrategyDossierFilterType.SingleSelect });
        }
        return j;
    }
    catch {
        return [];
    }
}

export const getJSONForReportFilterSave = (i: IReportBaseFilter[]): string => {
    const j: IReportBaseFilter[] = [];
    for (const p of i) {
        j.push({
            id: p.id,
            label: p.label,
            name: p.name,
            sortOrder: p.sortOrder,
            externalId: p.externalId,
            showSelectAll: p.showSelectAll,
            returnValues: p.returnValues,
            overrideValue: p.overrideValue,
            dossier: p.dossier,
            msId: p.msId,
            msName: p.msName,
            selectAllText: p.selectAllText,
            rangeIncrements: p.rangeIncrements,
            ignoreFilter: p.ignoreFilter,
            noFilterText: p.noFilterText,
            defaultSelection: p.defaultSelection,
            cascadePriority: p.cascadePriority || 99,
            cubeId: p.cubeId,
            mulitipleSelect: p.mulitipleSelect,
            rawValueSortIndex: p.rawValueSortIndex,
            selectAllLabel: p.selectAllLabel,
            filterType: p.filterType
        });
    }
    return JSON.stringify(j);
}

const sortConfigruationArray = (d: IReportFilter[]): IReportFilter[] => {
    return d.sort((n1, n2) => {
        if (n1.label > n2.label) {
            return 1;
        }
        if (n1.label < n2.label) {
            return -1;
        }
        return 0;
    });
};

export const getFiltersFromBaseFilters = (s: string, baseFilters: IReportFilter[]): IReportFilter[] => {
    const f: IReportBaseFilter[] = getReportBaseFilterFromJSON(s);
    const v: IReportFilter[] = [];
    for (const c of baseFilters) {
        if (f.filter(q => q.name === c.name).length > 0) {
            const i: IReportBaseFilter = f.filter(q => q.name === c.name)[0];
            v.push({
                ...c,
                dossier: i.dossier,
                ignoreFilter: i.ignoreFilter,
                cascadePriority: i.cascadePriority || 99,
                rangeIncrements: i.rangeIncrements,
                noFilterText: i.noFilterText,
                defaultSelection: i.defaultSelection,
                overrideValue: i.overrideValue,
                selectAllText: i.selectAllText,
                showSelectAll: i.showSelectAll,
                externalId: i.externalId,
                cubeId: i.cubeId,
                value: '',
                supportMultiple: i.filterType === 'M',
                filterType: i.filterType || MicroStrategyDossierFilterType.SingleSelect
            });
        }
    }
    return v;
}

export const getFiltersFromReportFilters = (reportFilters: IReportFilter[], baseFilters: IReportFilter[], initialValues?: string): IReportFilter[] => {
    const v: IReportFilter[] = [];
    if (initialValues) {
        const s: string[] = initialValues.split(':');
        let i: number = 0;
        while (i < 3) {
            if (s.length > i && reportFilters.length > i) {
                reportFilters[i].value = `h${s[i].replace('-', '')}`;
            }
            i = i + 1;
        }
    }
    for (const c of baseFilters) {
        let match = reportFilters.find(q => q.name === c.name);

        if (match) {
            v.push({
                ...c,
                dossier: match.dossier,
                ignoreFilter: match.ignoreFilter,
                cascadePriority: match.cascadePriority || 99,
                rangeIncrements: match.rangeIncrements,
                noFilterText: match.noFilterText,
                defaultSelection: match.defaultSelection,
                overrideValue: match.overrideValue,
                selectAllText: match.selectAllText,
                showSelectAll: match.showSelectAll,
                externalId: match.externalId,
                cubeId: match.cubeId,
                value: match.value,
                mulitipleSelect: match.mulitipleSelect,
                filterType: match.filterType
            });
        }
    }
    return v;
}