import React, { useEffect, useRef, useState } from 'react';
import OEInput from '../../core/components/form/OEInput';
import OEIcon from '../../core/components/general/OEIcon';
import { OECol } from '../../core/components/grid/OECol';
import { OERow } from '../../core/components/grid/OERow';
import { Icon } from '../../core/entities/Icon';
import { useOutsideAlerter } from '../../core/hooks/useOnClickOutside';
import { ICoreComponentInfo } from '../../site/components/OECoreComponent';
import { IGlossary, IGlossaryGroup } from '../entities/Glossary';
import { getGlossaryGroupList, useGetGlossaryTermGroup } from '../services/GlossaryService';

const Glossary: React.FunctionComponent<ICoreComponentInfo> = () => {

    const { service } = useGetGlossaryTermGroup();

    const elementRef = useRef<any>();

    const [isVisible, setIsVisible] = useState(false);
    const [items, setItems] = useState<IGlossaryGroup[]>([]);
    const [search, setSearch] = useState("")
    const [searchList, setSearchList] = useState<IGlossary[]>([])
    const wrapperRef = useRef(null);

    useOutsideAlerter(wrapperRef, () => {
        setSearchList([])
    });

    useEffect(() => {
        const handleScroll = () => {
            if (elementRef.current) {
                const rect = elementRef.current.getBoundingClientRect();
                const isVisible = (
                    rect.top >= 0 &&
                    rect.y >= 0
                );
                setIsVisible(!isVisible);
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (service.result) {
            setItems(getGlossaryGroupList(service.result));
        }
        // eslint-disable-next-line
    }, [service]);

    const scrollToLetter = (letter: string) => {
        const element = document.getElementById(letter);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            const glossaryItem = element.querySelector('.glossary-item') || element;

            setTimeout(() => {
                (glossaryItem as HTMLElement).focus();
            }, 500);

        }
    };

    const onSearchItemClick = (item: IGlossary) => {
        setSearch("");
        setSearchList([]);
        scrollToLetter(item.term);
    }

    return (
        <div ref={elementRef} id={"Glossary"} className="position-relative p30" style={{ height: 'calc(100% - 160px)' }}>
            <div >
                <div className="container-options">
                    {items.map(letter => (
                        <div translate='no' className='glossary-link-container' key={letter.name}>
                            <button className='glossary-link' onClick={() => scrollToLetter(letter.name)}>
                                {letter.name}
                            </button>
                        </div>
                    ))}

                    <div className='position-relative'>
                        <OEInput
                            className='glossary-search'
                            onChange={(e) => {
                                setSearch(e);
                                if (e.length > 2) {
                                    let objArray: IGlossary[] = [];
                                    items.forEach((glossary, i) => {
                                        let data = glossary.items.filter(item => item.term.toUpperCase().includes(e.toUpperCase()));
                                        if (data.length) {
                                            objArray = [...objArray, ...data];
                                        }
                                    });
                                    setSearchList(objArray);
                                } else {
                                    setSearchList([]);
                                }
                            }}
                            name="type"
                            placeholder='Glossary Search'
                            value={search}
                        />
                        <div className='suggestion-box'>
                            <i className="fa fa-search icon-container" />
                        </div>
                        {search.length !== 0 && search.length < 3 &&
                            <div className='suggestion-box suggestion-space'>
                                <p className='warning'>*Enter at least three characters</p>
                            </div>}

                        <div>
                            {searchList.length ?
                                <div className='search-container' ref={wrapperRef}>
                                    {searchList.map((item, index) =>
                                        <div tabIndex={0}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') onSearchItemClick(item)
                                            }}
                                            onClick={() => onSearchItemClick(item)}
                                            style={{ backgroundColor: 'white', position: 'relative', maxHeight: 80, padding: 5, borderBottomWidth: 2, borderBottomColor: '#dee2e6' }}>
                                            <p style={{ fontSize: 14, fontWeight: 400, }}>
                                                {item.term}</p>
                                            <p style={{ fontSize: 12, fontWeight: 300 }}>{item.definition}</p>
                                        </div>)}
                                </div> : null}
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="glossary-content"
                role="region"
                aria-label="Glossary"
                style={{ overflowY: 'scroll', height: '100% ', overflowX: 'hidden' }}
            >
                {items.map(letter => (
                    <OERow className="py-4" key={letter.name} id={letter.name}>
                        <OECol style={{ flex: .1 }} className="">
                            <h2 translate='no' className='glossary-group' id={`heading-${letter.name}`} aria-label={`Letter-${letter.name}`} >{letter.name}</h2>
                        </OECol>
                        <OECol style={{ flex: 2 }} className=""> {letter.items.map((glossary, i) =>
                            <div className='glossary-item' tabIndex={0} id={glossary.term} aria-label={`Term: ${glossary.term}. Definition: ${glossary.definition}`}>
                                <h3 className='glossary-term' aria-label={`Glossary Term: ${glossary.term}`}>{glossary.term}</h3>
                                <p className='glossary-definition' aria-label={`Definition: ${glossary.definition}`}>{glossary.definition}</p>
                                <hr></hr>
                            </div>
                        )}
                        </OECol>
                    </OERow>
                ))}
            </div>
            {
                isVisible &&
                <div className='bottom-right'>
                    <OEIcon icon={Icon.CaretUp} onClick={() => scrollToLetter("Glossary")} />
                </div>
            }
        </div >
    );
};

export default Glossary;