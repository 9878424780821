import React, { useEffect, useState } from 'react';
import { ICarousalItem } from '../../../../core/components/carousal/entities/Carousal';
import OESpinner from '../../../../core/components/messaging/OESpinner';
import { navigateToPage } from '../../../../core/utilities/URL';
import { useGetSchoolDistricts } from '../../../../reporting/services/ReportFilterService';
import { ICoreComponentInfo } from '../../../components/OECoreComponent';
import OrganizationItem from './OrganizationItem';

interface IComponentInfo {
    typeId: string;
}

const OrganizationList: React.FunctionComponent<IComponentInfo & ICoreComponentInfo> = ({ routeProperties, typeId }) => {

    // const [selectedItem, setSelectedItem] = useState(0); // Carousal Changes
    const [selectedItem, setSelectedItem] = useState('A');

    const { service, setSearch } = useGetSchoolDistricts();
    const [items, setItems] = useState<ICarousalItem[]>([])

    const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

    useEffect(() => {
        setSearch({ keyword: '%', academicYear: routeProperties.academicYearID, isSchool: true });
        // eslint-disable-next-line
    }, [routeProperties.academicYearID]);

    useEffect(() => {
        if (service.result) {
            const d: ICarousalItem[] = [];
            for (const v of service.result.filter(q => q.typeId === typeId)) {
                d.push({ value: v.id, title: v.name });
            }

            setItems(d);
        }
        // eslint-disable-next-line
    }, [service]);

    const navigateToSchoolReports = (item: ICarousalItem) => {
        navigateToPage(`/kysrc?organization=${item.value}`)
    }

    const handleLetterClick = (letter: string) => {
        setSelectedItem(letter)
    };

    return (
        // Carousal Changes
        // <div className="p-3 flex-grow-1 bg-white">
        //     <Carousal onClickItem={(i) => { console.log("Schools click", i) }} selectedItem={selectedItem} items={SCHOOL_TITLE} setSelectedItem={setSelectedItem} />
        //     <OESpinner className="d-flex justify-content-center" hidden={!orgService.isInProgress} />

        //     {!orgService.isInProgress && (
        //         <>
        //             <div className="schoolContainer">
        //                 <OrganizationItem titleIndex={0} items={items} selectedItem={selectedItem} navigateToSchoolReports={navigateToSchoolReports} />
        //                 <OrganizationItem titleIndex={1} items={items} selectedItem={selectedItem} navigateToSchoolReports={navigateToSchoolReports} />
        //                 <OrganizationItem titleIndex={2} items={items} selectedItem={selectedItem} navigateToSchoolReports={navigateToSchoolReports} />
        //             </div>
        //         </>
        //     )}
        // </div>
        <>
            <div className="p-3 flex-grow-1 bg-white" style={styles.container}>
                <div className="container-options">
                    {alphabet.map((letter) => (
                        <div translate="no" className='glossary-link-container' key={letter}>
                            <button className={`glossary-link ${selectedItem === letter ? 'glossary-link-active' : ''}`} onClick={() => handleLetterClick(letter)} >
                                {letter}
                            </button>
                        </div>
                    ))}
                </div>
                <OESpinner message="Loading list" hidden={!service.isInProgress} />
                {!service.isInProgress && (
                    <div className="schoolContainer">
                        <OrganizationItem titleIndex={0} items={items} selectedItem={selectedItem} navigateToSchoolReports={navigateToSchoolReports} />
                    </div>
                )}
            </div>
        </>
    );
};

export default OrganizationList;

const styles = {
    container: {
        height: 'calc(100 % - 300px)',
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
};
