import React, { useEffect, useState } from 'react';
import OEAutoComplete from '../../../core/components/form/OEAutoComplete';
import OEDropdown, { IDropdownValues } from '../../../core/components/form/OEDropdown';
import { navigateToPage } from '../../../core/utilities/URL';
import { IHeaderText } from '../../entities/HeaderText';
import { getSiteSetting, saveSiteSetting, SiteSetting } from '../../entities/SiteSettings';
import { ICustomRouteProperties } from '../entities/CustomRouteProperties';
import { ISchoolOptionsSelection } from '../entities/SchoolOptionsSelection';
import { useResponsivenessContext } from '../../../core/contexts/Resposiveness';

import LoginUserProfile from './LoginUserProfile';

interface IComponentInfo {
    toggleCollapse: () => void;
    collapsed: boolean;
    headerText: IHeaderText;
    isCenter?: boolean;
    pathname?: string;
    headerWidth?: number;
    layout: number;
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void;
    reportingPage?: boolean;
    width: number;
    schoolDistricts: ISchoolOptionsSelection[];
    onSearchSchoolDistricts: (val: string) => void;
    academicYears: IDropdownValues[];
}

const Header: React.FunctionComponent<IComponentInfo> = ({
    collapsed, isCenter, pathname, layout, routeProperties, setRouteProperties, reportingPage,
    width, toggleCollapse, onSearchSchoolDistricts, schoolDistricts, academicYears
}) => {

    const [inputValue, setInputValue] = useState<string>('')
    const [year, setYear] = useState<string>(getSiteSetting(SiteSetting.AcademicYear));
    const typeScreen = useResponsivenessContext()

    const backgroundImageContainer = pathname === "/" || pathname === '/home' ? '#ffffff' : '#f7f7f7';
    const backgroundColorHeader = pathname === "/" || pathname === '/home' ? '#ffffff' : '#007780';

    useEffect(() => {
        const y = academicYears.find(q => q.id === year);
        if (y) {
            setRouteProperties({ ...routeProperties, academicYearID: y.id, academicYear: y.name });
            saveSiteSetting(SiteSetting.AcademicYear, y.id);
        }
        // eslint-disable-next-line
    }, [year]);

    const onNavigate = (value: string) => {
        navigateToPage(`/kysrc?organization=${value}`)
    }

    const onSearch = (val: string) => {
        setInputValue(val);
        onSearchSchoolDistricts(val);
    }

    const renderDefaultHomePageHeader = () => {
        return (
            <div className="header-layout" style={{ backgroundColor: backgroundColorHeader }}>
                <div className='logo-container'>
                    <img
                        height={80}
                        className="header-logo"
                        src={`/images/logo/header-logo-background.png`}
                        alt="ketucky logo"
                    />
                </div>
                <div className="dropdown text-end profile-container"
                    style={{
                        justifyContent: 'right',
                        backgroundColor: '#fff'
                    }}>
                </div>
                <LoginUserProfile
                    routeProperties={routeProperties}
                    setRouteProperties={setRouteProperties}
                    reportingPage={false}
                    className="m-t-10"
                />
            </div >
        )
    }

    const renderLayoutResponsive = () => {
        return (
            <div className="header-layout" style={{ backgroundColor: 'white', justifyContent: 'space-between', display: 'flex' }}>
                <img
                    className="header-logo m-l-20"
                    src={`/images/menu.svg`}
                    alt="header-logo"
                    width={25}
                    onClick={toggleCollapse}
                    style={{ color: '#578F91' }}
                />
                <div className='logo-container' onClick={toggleCollapse} >
                    <img
                        height={80}
                        className="header-logo"
                        src={`/images/logo/header-logo-background.png`}
                        alt="ketucky logo"
                    />
                </div>
                <LoginUserProfile
                    routeProperties={routeProperties}
                    setRouteProperties={setRouteProperties}
                    reportingPage={false}
                    responsive={true}
                />
            </div >
        )
    }

    const renderHeaderLayouts = () => {
        return (
            <div className="header-layout" style={{ backgroundColor: '#ffffff' }}>
                <div style={{
                    width: 290,
                    backgroundColor: backgroundImageContainer,
                    height: 80
                }}>
                    <img
                        height={80}
                        className="header-logo"
                        src={`/images/logo/header-logo-background.png?v=${Math.random() * 1000}`}
                        alt=""
                    />
                    <div style={{ padding: 20 }}>
                        <div className='separator' />
                    </div>
                </div>
                <div className="dropdown text-end profile-container"
                    style={{
                        justifyContent: isCenter ? 'center' : 'space-between',
                        alignItems: 'center'
                    }}>
                    <div className="m-l-10 text-header" style={{ color: '#1B6769' }}>
                        <h3>Report Card Dashboards</h3>
                    </div>
                    <div className="m-l-10 text-header">
                        <div className='box-container flex'>
                            <div className="m-t-5 container-dropdown-data" >
                                <p style={{ color: 'black', fontWeight: 'bold' }}>School Year</p>
                            </div>
                            <div className="m-l-10" >
                                <OEDropdown
                                    values={academicYears}
                                    value={year}
                                    onChange={setYear}
                                    className='dropdown-header-year'
                                />
                            </div>
                            <div className='m-l-10'>
                                <OEAutoComplete
                                    options={schoolDistricts}
                                    style={{ width: 300, borderRadius: 50, height: 40, textAlign: 'left' }}
                                    onNavigate={onNavigate}
                                    onSearch={onSearch}
                                    placeholder="Search by school or district name"
                                    value={inputValue}
                                    titleStyle='title-search-layout'
                                />
                            </div>
                            <LoginUserProfile
                                routeProperties={routeProperties}
                                setRouteProperties={setRouteProperties}
                                reportingPage={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {layout === 2 && (pathname === "/" || pathname === "/home")
                ? renderDefaultHomePageHeader()
                : typeScreen === "web"
                    ? renderHeaderLayouts()
                    : renderLayoutResponsive()
            }
        </>
    );
}

export default Header;